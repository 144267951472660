@import '../../../styles/variables.module.scss';
@import '../../../styles/mixins.module.scss';

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 1.125rem 1.25rem;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
  @media screen and (min-width: 1200px) {
    padding: 1.875rem 3.125rem;
  }
  .headerLogo {
    @include logo;
  }
  .headerActions {
    button {
      @include button-secondary;
    }
  }
}