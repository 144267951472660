/* styles/variables.module.css */
:root {
  --primary-color: #775FB3;
  --secondary-color: #4caf50;

  --white: #fff;
  --gray: #F3F6FC;

  --text-light: rgba(17,17,17,0.5);

  --border-default: #dcdcdc;
}

