.footer {
  bottom: 1.875rem;
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 10;
  p {
    font-size: 0.625rem;
    line-height: 1.166666666666667;
    margin: 0 auto;
    max-width: 77vw;
    text-align: center;
    @media screen and (min-width: 600px) {
      max-width: 500px;
    }
  }
}