@import './variables.module.scss';

@mixin h1 {
  font-size: 12vw;
  font-weight: 800;
  text-align: center;
  margin: 0 0 2.125rem;
  max-width: 54rem;
  mix-blend-mode: exclusion;
  @media screen and (min-width: 600px) {
    font-size: 7.65625vw;
  }
}

@mixin logo {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  img {
    height: 3.75rem;
    margin: 0 0 0.5rem 0;
    margin-right: 0;
    width: auto;
    @media screen and (min-width: 600px) {
      margin: 0 1.375rem 0 0;
    }
  }
  span {
    font-size: 1.125rem;
    font-weight: 600;
    width: 100%;
    @media screen and (min-width: 600px) {
      width: auto;
    }
  }
}

@mixin button-primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 3.125rem;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 800;
  height: 3.5rem;
  overflow: hidden;
  padding: 0.9375rem 1.75rem;
  width: auto;
  &:hover,
  &:focus {
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
  }
}

@mixin button-secondary {
  background-color: transparent;
  border: 0;
  border-radius: 3.125rem;
  color: #192A38;
  font-size: 1.25rem;
  font-weight: 800;
  height: 3.25rem;
  overflow: hidden;
  padding: 0.9375rem 1.75rem;
  position: relative;
  width: auto;
  &:after {
    background: var(--white);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    background-blend-mode: exclusion;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  span {
    position: relative;
    z-index: 2;
  }
  &:hover,
  &:focus {
    color: var(--white);
    &:after {
      background: var(--primary-color);
      border: 1px solid var(--primary-color);

    }
  }
}

@mixin input-text-default {
  background: var(--gray);
  border: 1px solid var(--gray);
  border-radius: 3.125rem;
  color: #111;
  font-size: 1.25rem;
  font-weight: 500;
  height: 3.375rem;
  overflow: hidden;
  padding: 0.9375rem 1.75rem;
  width: 100%;


  &::placeholder {
    color: var(--text-light);
  }
}